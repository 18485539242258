import React from "react";
import { useLangContext } from "../../lang-context";

function FinancialReport() {
  const { lang } = useLangContext();
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr overlay-black-middle bg-pt"
        style={{
          backgroundImage:
            "url(" + require("../../images/banner/bnr4.jpg") + ")",
          height: 70,
        }}
      ></div>
      <div className="content-block">
        <div className="section-full content-inner bg-white">
          <div className="container">
              <div
                className="content-bx1 m-b40"
                style={{
                  paddingLeft: 0,
                }}
              >
                <h3 className="m-b15 title">
                  {lang === "mn" ? "САНХҮҮГИЙН ТАЙЛАН" : "FINANCIAL REPORT"}
                </h3>
              </div>
            <div className="row">
              <div class="table-responsive">
                <table class="table table-bordered table-striped table-hover table-condensed">
                  <thead>
                    <tr>
                      <th>{lang === "mn" ? "№" : "No."}</th>
                      <th>
                        {lang === "mn" ? "Компанийн нэр" : "Company name"}
                      </th>
                      <th>{lang === "mn" ? "Симбол" : "Symbol"}</th>
                      <th>{lang === "mn" ? "Товч тайлбар" : "Description"}</th>
                      <th>
                        {lang === "mn" ? "Нийтэлсэн огноо" : "Published date"}
                      </th>
                      <th colSpan={2}>
                        {lang === "mn" ? "Татаж авах" : "Download"}
                      </th>
                      <th colSpan={2}>
                        {lang === "mn"
                          ? "Аудит хийсэн байгууллага, огноо"
                          : "Audited organization, date"}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>{lang === "mn" ? "Эм Эн Ди ХК" : "MND LLC"}</td>
                      <td>DLH</td>
                      <td>
                        {lang === "mn"
                          ? "2022 оны 4-р улирлын тайлан"
                          : "2022 financial report for Q4"}
                      </td>
                      <td>2023-02-10</td>
                      <td class="text-center">
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820224report.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-pdf-o"></i>
                        </a>
                      </td>
                      <td>
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820224report.xls"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-excel-o"></i>
                        </a>
                      </td>

                      <td></td>
                      <td></td>
                      <td class="text-center"> </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>{lang === "mn" ? "Эм Эн Ди ХК" : "MND LLC"}</td>
                      <td>DLH</td>
                      <td>
                        {lang === "mn"
                          ? "2021 оны 4-р улирлын тайлан"
                          : "2021 financial report for Q4"}
                      </td>
                      <td>2022-02-10</td>
                      <td class="text-center">
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820214report.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-pdf-o"></i>
                        </a>
                      </td>
                      <td>
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820214report.xls"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-excel-o"></i>
                        </a>
                      </td>

                      <td>СИ ЭС АЙ АУДИТ ХХК</td>
                      <td>2022-03-15</td>
                      <td class="text-center">
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820214Audit.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-pdf-o"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>{lang === "mn" ? "Эм Эн Ди ХК" : "MND LLC"}</td>
                      <td>DLH</td>
                      <td>
                        {lang === "mn"
                          ? "2020 оны 4-р улирлын тайлан"
                          : "2020 financial report for Q4"}
                      </td>
                      <td>2021-03-22</td>
                      <td class="text-center"></td>
                      <td>
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820204report.xls"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-excel-o"></i>
                        </a>
                      </td>

                      <td>Акпар Аудит ХХК</td>
                      <td>2021-02-24</td>
                      <td class="text-center"> </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>{lang === "mn" ? "Эм Эн Ди ХК" : "MND LLC"}</td>
                      <td>DLH</td>
                      <td>
                        {lang === "mn"
                          ? "2020 оны 2-р улирлын тайлан"
                          : "2020 financial report for Q2"}
                      </td>
                      <td>2020-07-20</td>
                      <td class="text-center">
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820202report.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-pdf-o"></i>
                        </a>
                      </td>
                      <td>
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820202report.xls"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-excel-o"></i>
                        </a>
                      </td>

                      <td></td>
                      <td></td>
                      <td class="text-center"> </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>{lang === "mn" ? "Эм Эн Ди ХК" : "MND LLC"}</td>
                      <td>DLH</td>
                      <td>
                        {lang === "mn"
                          ? "2019 оны 4-р улирлын тайлан"
                          : "2019 financial report for Q4"}
                      </td>
                      <td>2020-02-13</td>
                      <td class="text-center">
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820191report.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-pdf-o"></i>
                        </a>
                      </td>
                      <td>
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820194report.xls"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-excel-o"></i>
                        </a>
                      </td>

                      <td>Акпар аудит ХХК</td>
                      <td>2020-03-26</td>
                      <td class="text-center">
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820194Audit.rar"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-pdf-o"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>{lang === "mn" ? "Эм Эн Ди ХК" : "MND LLC"}</td>
                      <td>DLH</td>
                      <td>
                        {lang === "mn"
                          ? "2019 оны 2-р улирлын тайлан"
                          : "2019 financial report for Q2"}
                      </td>
                      <td>2019-07-19</td>
                      <td class="text-center">
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820192report.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-pdf-o"></i>
                        </a>
                      </td>
                      <td>
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820192report.xls"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-excel-o"></i>
                        </a>
                      </td>

                      <td></td>
                      <td></td>
                      <td class="text-center"> </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>{lang === "mn" ? "Эм Эн Ди ХК" : "MND LLC"}</td>
                      <td>DLH</td>
                      <td>
                        {lang === "mn"
                          ? "2018 оны 4-р улирлын тайлан"
                          : "2018 financial report for Q4"}
                      </td>
                      <td>2019-02-19</td>
                      <td class="text-center">
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820184report.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-pdf-o"></i>
                        </a>
                      </td>
                      <td>
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820184report.xls"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-excel-o"></i>
                        </a>
                      </td>

                      <td>ЭМО-АУДИТ ХХК</td>
                      <td>2019-02-22</td>
                      <td class="text-center"> </td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>{lang === "mn" ? "Эм Эн Ди ХК" : "MND LLC"}</td>
                      <td>DLH</td>
                      <td>
                        {lang === "mn"
                          ? "2018 оны 2-р улирлын тайлан"
                          : "2018 financial report for Q2"}
                      </td>
                      <td>2018-07-25</td>
                      <td class="text-center">
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820182report.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-pdf-o"></i>
                        </a>
                      </td>
                      <td>
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820182report.xls"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-excel-o"></i>
                        </a>
                      </td>

                      <td></td>
                      <td></td>
                      <td class="text-center"> </td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>{lang === "mn" ? "Эм Эн Ди ХК" : "MND LLC"}</td>
                      <td>DLH</td>
                      <td>
                        {lang === "mn"
                          ? "2017 оны 4-р улирлын тайлан"
                          : "2017 financial report for Q4"}
                      </td>
                      <td>2018-02-08</td>
                      <td class="text-center">
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820174report.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-pdf-o"></i>
                        </a>
                      </td>
                      <td>
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820174report.xls"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-excel-o"></i>
                        </a>
                      </td>

                      <td>"Ас-Арвай аудит" ХХК</td>
                      <td>2018-03-26</td>
                      <td class="text-center"> </td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>{lang === "mn" ? "Эм Эн Ди ХК" : "MND LLC"}</td>
                      <td>DLH</td>
                      <td>
                        {lang === "mn"
                          ? "2017 оны 2-р улирлын тайлан"
                          : "2017 financial report for Q2"}
                      </td>
                      <td>2017-07-25</td>
                      <td class="text-center">
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820172report.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-pdf-o"></i>
                        </a>
                      </td>
                      <td>
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820172report.xls"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-excel-o"></i>
                        </a>
                      </td>

                      <td></td>
                      <td></td>
                      <td class="text-center"> </td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>{lang === "mn" ? "Эм Эн Ди ХК" : "MND LLC"}</td>
                      <td>DLH</td>
                      <td>
                        {lang === "mn"
                          ? "2016 оны 4-р улирлын тайлан"
                          : "2016 financial report for Q4"}
                      </td>
                      <td>2017-05-29</td>
                      <td class="text-center">
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820164report.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-pdf-o"></i>
                        </a>
                      </td>
                      <td>
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820164report.xls"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-excel-o"></i>
                        </a>
                      </td>

                      <td>Эс Жи Эм Ди Аудит ХХК</td>
                      <td>2017-05-02</td>
                      <td class="text-center"> </td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <td>{lang === "mn" ? "Эм Эн Ди ХК" : "MND LLC"}</td>
                      <td>DLH</td>
                      <td>
                        {lang === "mn"
                          ? "2015 оны 4-р улирлын тайлан"
                          : "2015 financial report for Q4"}
                      </td>
                      <td>2016-06-10</td>
                      <td class="text-center">
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820154report.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-pdf-o"></i>
                        </a>
                      </td>
                      <td>
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820154report.xls"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-excel-o"></i>
                        </a>
                      </td>

                      <td></td>
                      <td></td>
                      <td class="text-center"> </td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>{lang === "mn" ? "Эм Эн Ди ХК" : "MND LLC"}</td>
                      <td>DLH</td>
                      <td>
                        {lang === "mn"
                          ? "2013 оны 4-р улирлын тайлан"
                          : "2013 financial report for Q4"}
                      </td>
                      <td>2014-04-15</td>
                      <td class="text-center">
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820134report.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-pdf-o"></i>
                        </a>
                      </td>
                      <td>
                        {" "}
                        <a
                          href="https://mse.mn/uploads/finance/11820134report.xls"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i class="fa fa-file-excel-o"></i>
                        </a>
                      </td>

                      <td></td>
                      <td></td>
                      <td class="text-center"> </td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>{lang === "mn" ? "Эм Эн Ди ХК" : "MND LLC"}</td>
                      <td>DLH</td>
                      <td>
                        {lang === "mn"
                          ? "2010 оны 4-р улирлын тайлан"
                          : "2010 financial report for Q4"}
                      </td>
                      <td>2011-03-31</td>
                      <td class="text-center"></td>
                      <td></td>

                      <td></td>
                      <td></td>
                      <td class="text-center"> </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinancialReport;
