import React from "react";
import { Link } from "react-router-dom";
import { useLangContext } from "../../../lang-context";

const newsImage2 = require("../../../images/new/news4.png");
const News2 = () => {
  const { lang } = useLangContext();

  return (
    <>
      {" "}
      <div className="dlab-post-meta">
        <ul>
          <li className="post-date">
            {" "}
            <strong>11 Sep</strong> <span> 2017</span>{" "}
          </li>
          <li className="post-author">
            {" "}
            By <Link to="/#">Uuganbold</Link>{" "}
          </li>
        </ul>
      </div>
      <div className="dlab-post-title ">
        <h4 className="post-title m-t0">
          <Link to="/blog-single-sidebar">
            "СЭЛЭНГЭ ДУЛААНХАН” ХК-ИЙН ХУВЬЦААГ ХУДАЛДАН АВАХ ТЕНДЕР САНАЛ
          </Link>
        </h4>
      </div>
      <div className="dlab-post-text">
        <img className="alignleft" width="300" src={newsImage2} alt="" />
        <p>
          "Сэлэнгэ дулаанхан” ХК-ийн хяналтын багцыг эзэмшигч Н.Отгон-од нь
          "Компанийн тухай" хуулийн 57.1, "Үнэт цаасны зах зээлийн тухай"
          хуулийн 22 дахь заалт, СЗХ-ны "Хувьцаат компанийн хувьцааг худалдан
          авах санал гаргах, худалдан авах үйл ажиллагааны журам"-ын 3.1-д
          заасны дагуу бусад хувьцаа эзэмшигч нарын өмчлөлд байгаа нийт 64,360
          ширхэг хувьцааг нэг бүрийг нь 600 төгрөгөөр худалдан авах тендер санал
          гаргаж байна.
        </p>
        <p>
          <b>Санал болгож буй үнэ:</b> 600 /зургаан зуун/ төгрөг
        </p>
        <p>
          <b>Худалдан авах тоо ширхэг:</b> 64,360 ширхэг
        </p>
        <p>
          <b>Хувьцааг худалдан авах саналын эхлэх хугацаа:</b> 2017 оны 9 сарын
          04-ний өдөр
        </p>
        <p>
          <b>Хувьцааг худалдан авах саналын дуусах хугацаа:</b> 2017 оны 10
          сарын 03-ны өдөр
        </p>
        <p>
          <b>Хувьцааны худалдан авах төлбөрийн хугацаа:</b> 2017 оны 10 сарын
          05-ны өдөр
        </p>
        <p>
          <b>Хаяг:</b> Улаанбаатар хот, Баянгол дүүрэг, 16-р хороо,
          Амарсанаагийн гудамж - 12/1,Колорадо Бизнес Төв, 2-р давхар 209а тоот
        </p>
        <p>
          Дэлгэрэнгүй мэдээллийг <b>99998707</b> утсаар авна уу.
        </p>
      </div>
    </>
  );
};

export default News2;
