import React from "react";
import { useLangContext } from "../../lang-context";
var bnr3 = require("./../../images/banner/bnr3.jpg");
var bg1 = require("./../../images/background/bg-video.png");

const About1 = ({}) => {
  const { lang } = useLangContext();
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr overlay-black-middle text-center bg-pt"
        style={{ backgroundImage: "url(" + bnr3 + ")", height: 70 }}
      ></div>
      <div className="content-block">
        <div
          className="section-full content-inner bg-white video-section"
          style={{
            backgroundImage: "url(" + bg1 + ")",
            minHeight: "calc(100vh - 140px)",
          }}
        >
          <div className="container">
            <div className="section-content">
              <div className="row d-flex">
                <div className="col-lg-12">
                  <div className="content-bx1 m-b40">
                    <h3 className="m-b15 title">
                      ТӨЛӨӨЛӨН УДИРДАХ ЗӨВЛӨЛИЙН ДАРГА МЭНДЧИЛГЭЭ
                    </h3>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 m-b30 video-infobx">
                  <div className="content-bx1">
                    <div className="m-b30" style={{ width: 300 }}>
                      <img
                        src={require("../../images/new/ceo-profile.jpeg")}
                        alt="Signature"
                        className="m-b30"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-12 m-b30 video-infobx">
                  <div className="content-bx1">
                    <p className="m-b30">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here, content here', making it
                      look like readable English. Many desktop publishing
                      packages and web page editors now use Lorem Ipsum as their
                      default model text, and a search for 'lorem ipsum' will
                      uncover many web sites still in their infancy. Various
                      versions have evolved over the years, sometimes by
                      accident, sometimes on purpose (injected humour and the
                      like).
                    </p>
                    {/* <img
                        src={require("../../images/sign.png")}
                        width="200"
                        alt=""
                      /> */}
                    <h4 className="m-b0">
                      {lang === "mn" ? "Н.Ганчимэг" : "N.Ganchimeg"}
                    </h4>
                    <span className="font-14">
                      {lang === "mn"
                        ? "ТӨЛӨӨЛӨН УДИРДАХ ЗӨВЛӨЛИЙН ДАРГА"
                        : "CHAIRMAN"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About1;
