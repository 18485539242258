import React from "react";
import { useLangContext } from "../../lang-context";

function Shareholders() {
  const { lang } = useLangContext();
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr overlay-black-middle bg-pt"
        style={{
          backgroundImage:
            "url(" + require("../../images/banner/bnr4.jpg") + ")",
          height: 70,
        }}
      ></div>
      <div className="content-block">
        <div className="section-full content-inner bg-white">
          <div className="container">
            <div
              className="content-bx1 m-b40"
              style={{
                paddingLeft: 0,
              }}
            >
              <h3
                className="m-b15 title"
                style={{ textTransform: "uppercase" }}
              >
                {lang === "mn"
                  ? "НӨЛӨӨ БҮХИЙ ХУВЬЦАА ЭЗЭМШИГЧИД"
                  : "Shareholders Organizational"}
              </h3>
            </div>
            <div class="table-responsive">
              <table
                class="table dividend trade_table table-bordered table-striped table-hover table-condensed"
                role="grid"
                aria-describedby="dt_basic_info"
              >
                <thead>
                  <tr>
                    <th style={{ width: 30 }}>№</th>
                    <th width="250px">Овог Нэр</th>
                    <th>Эзэмшиж буй хувьцааны хувь</th>
                    <th>Бусад компанийн хувьцааг эзэмшиж буй байдал</th>
                    <th>Түүнтэй нэгдмэл сонирхолтой этгээд</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>PIONG CHOONG FAH</td>
                    <td>17.61 %</td>
                    <td>17.61 %</td>
                    <td>Байхгүй </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Намсрай Отгон-Од</td>
                    <td>33.89 %</td>
                    <td>33.89 %</td>
                    <td>Байхгүй </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>LEW WEN TEIK</td>
                    <td>5.14 %</td>
                    <td>5.14 %</td>
                    <td>Байхгүй </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Чунг Чии Иенн</td>
                    <td>17.59 %</td>
                    <td>17.59 %</td>
                    <td>Байхгүй </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shareholders;
