import React from "react";
import { Link } from "react-router-dom";
import { useLangContext } from "../../../lang-context";

const newsImage3 = require("../../../images/new/news5.png");
const News3 = () => {
  const { lang } = useLangContext();

  return (
    <>
      {" "}
      <div className="dlab-post-meta">
        <ul>
          <li className="post-date">
            {" "}
            <strong>09 Nov</strong> <span> 2017</span>{" "}
          </li>
          <li className="post-author">
            {" "}
            By <Link to="/#">Uuganbold</Link>{" "}
          </li>
        </ul>
      </div>
      <div className="dlab-post-title ">
        <h4 className="post-title m-t0">
          <Link to="/blog-single-sidebar">
            {lang === "mn"
              ? "'Эм Эн Ди' (DLH+14.42%)-ийн хувьцааны ханш хамгийн өндөр өсөв."
              : "The share price of 'MND' (DLH+14.42%) rose the highest."}
          </Link>
        </h4>
      </div>
      <div className="dlab-post-text">
        <img className="alignleft" width="300" src={newsImage3} alt="" />
        <p>
          Улаанбаатар /МОНЦАМЭ/. Монголын Хөрөнгийн биржид энгийн арилжаагаар 44
          ХК-ийн 1.3 сая ширхэг хувьцааг 470,009,772 төгрөгөөр арилжив. Үүнээс
          24 ХК-ийн хувьцааны ханш дээшилж, 17 ХК-ийн хувьцааны ханш буурсан бол
          3 компанийн хувьцааны ханш тогтвортой байлаа.
        </p>
        <p>
          Энэ өдөр "Тахь-ко” (TAH+15.00%), "Эм Эн Ди" (DLH+14.42%) болон "Шарын
          гол" ХК (SHG+11.93%)-ийн хувьцааны ханш хамгийн өндөр өсөв. Харин
          "Булган ундрага" (BUN-10.34%), "Ариг гал" (EER-4.78%) болон "Ард
          санхүүгийн нэгдэл" ХК (AARD -4.55%)-ийн хувьцаа хамгийн их уналттай
          байлаа.
        </p>
        <p>
          Хөрөнгө оруулалтын 2 сангийн 52,705 ширхэг нэгж эрхийг 20.5 сая
          төгрөгөөр арилжсан байна. ТOП-20 индекс 0.39 хувиар өсөж, 40,477.29
          нэгжид хүрсэн бол зах зээлийн үнэлгээ 5,738,734,664,958 төгрөг боллоо.
        </p>
      </div>
    </>
  );
};

export default News3;
