import React from "react";
import { useLangContext } from "../../lang-context";
import "./stockPrice.scss";

const CompanyStockPricePage = () => {
  const { lang } = useLangContext();
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr overlay-black-middle bg-pt"
        style={{
          backgroundImage:
            "url(" + require("../../images/banner/bnr4.jpg") + ")",
          height: 70,
        }}
      ></div>
      <div className="content-block">
        <div className="section-full content-inner bg-white">
          <div className="container">
            <div className="row">
              <div className="header">
                <h1 className="title">
                  {lang === "mn" ? "ХУВЬЦААНЫ ҮНЭ" : "COMPANY STOCK PRICE"}
                </h1>
                <div className="price">
                  <div className="price__value">0.00₮</div>
                  <div className="price__change">+0 ( 0.00% )</div>
                </div>
              </div>
              <div className="content">
                <div className="col-lg-6 col-md-6 info-section">
                  <ul className="info-list">
                    <li className="info-item">
                      <span className="info-key">
                        {lang === "mn"
                          ? "Өмнөх өдрийн хаалт: "
                          : "Previous Day Close: "}
                      </span>
                      0.00
                    </li>
                    <li className="info-item">
                      <span className="info-key">
                        {lang === "mn" ? "Нээлтийн ханш: " : "Bid: "}
                      </span>
                      0.00
                    </li>
                    <li className="info-item">
                      <span className="info-key">
                        {lang === "mn" ? "Хаалтын ханш: " : "Ask: "}
                      </span>
                      {lang === "mn" ? "N/A" : "N/A"}
                    </li>
                    <li className="info-item">
                      <span className="info-key">
                        {lang === "mn"
                          ? "Тухайн өдрийн VWAP: "
                          : "Today's VWAP: "}
                      </span>
                      0.00
                    </li>
                    <li className="info-item">
                      <span className="info-key">
                        {lang === "mn" ? "Авах дээд үнэ: " : "High Buy Price: "}
                      </span>
                      0.00 x 0
                    </li>
                    <li className="info-item">
                      <span className="info-key">
                        {lang === "mn"
                          ? "Зарах доод үнэ: "
                          : "Low Sell Price: "}
                      </span>
                      0.00 x 0
                    </li>
                    <li className="info-item">
                      <span className="info-key">
                        {lang === "mn"
                          ? "Нийт гаргасан хувьцаа: "
                          : "Total Traded Volume: "}
                      </span>
                      97,360
                    </li>
                    <li className="info-item">
                      <span className="info-key">
                        {lang === "mn"
                          ? "Эргэлтэд гаргасан хувьцаа: "
                          : "Volume Traded Today: "}
                      </span>
                      97,360
                    </li>
                  </ul>
                </div>
                <div class="col-lg-6 col-md-6 info-section">
                  <ul className="info-list">
                    <li className="info-item">
                      <span className="info-key">
                        {lang === "mn" ? "Өдрийн хязгаар: " : "Daily limit: "}
                      </span>
                      0.00 - 0.00
                    </li>
                    <li className="info-item">
                      <span className="info-key">
                        {lang === "mn"
                          ? "Арилжигдсан ширхэг: "
                          : "Traded shares: "}
                      </span>
                      0
                    </li>
                    <li className="info-item">
                      <span className="info-key">
                        {lang === "mn"
                          ? "Арилжааны үнийн дүн: "
                          : "Traded value: "}
                      </span>
                      0.00
                    </li>
                    <li className="info-item">
                      <span className="info-key">
                        {lang === "mn"
                          ? "Хэлцлийн тоо: "
                          : "Number of transactions: "}
                      </span>
                      0
                    </li>
                    <li className="info-item">
                      <span className="info-key">
                        {lang === "mn" ? "52 долоо хоног: " : "52 week range: "}
                      </span>
                      880.00 - 990.00
                    </li>
                    <li className="info-item">
                      <span className="info-key">ISIN: </span>MN00DLH01189
                    </li>
                    <li className="info-item">
                      <span className="info-key">
                        {lang === "mn"
                          ? "Зах зээлийн үнэлгээ: "
                          : "Market capitalization: "}
                      </span>{" "}
                      85,676,800.00
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyStockPricePage;
