import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";
import { useLangContext } from "../../lang-context";
import "./header.scss";

const Header3 = ({}) => {
  const { lang, handleLanguageChange } = useLangContext();
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    // sidebar open/close
    const btn = document.querySelector(".navicon");
    const nav = document.querySelector(".header-nav");

    const toggleFunc = () => {
      btn.classList.toggle("open");
      nav.classList.toggle("show");
    };

    btn.addEventListener("click", toggleFunc);

    // Sidenav li open close
    const navUl = [...document.querySelectorAll(".header-nav > ul > li")];
    navUl.forEach((li) => {
      li.addEventListener("click", () => {
        checkLi(li);
      });
    });

    const checkLi = (current) => {
      const active = current.classList.contains("open");
      navUl.forEach((el) => el.classList.remove("open"));

      if (active) {
        current.classList.remove("open");
      } else {
        current.classList.add("open");
      }
    };

    return () => {
      btn.removeEventListener("click", toggleFunc);
      navUl.forEach((li) => {
        li.removeEventListener("click", () => {
          checkLi(li);
        });
      });
    };
  }, []);

  const handleStateChange = (status) => {
    const fixed = status.status === Sticky.STATUS_FIXED;
    setFixed(fixed);
  };

  const active = fixed ? "is-fixed" : "";
  return (
    <>
      <Sticky innerZ={999} enabled={true} onStateChange={handleStateChange}>
        <header className="site-header mo-left header-transparent header navstyle3">
          <div
            className={`sticky-header main-bar-wraper ${active} navbar-expand-lg`}
          >
            <div className="main-bar clearfix ">
              <div className="container clearfix">
                <div className="logo-header mostion">
                  <Link
                    style={{ textTransform: "none", textAlign: "center" }}
                    to="/"
                  >
                    <img src={require("../../images/mnd/logo.png")} alt="" />
                    <p className="logo-text">
                      {lang === "mn" ? (
                        <>
                          <span>ЭМ</span>
                          <span>ЭН</span>
                          <span className="letter-spacing-0">ДИ</span>
                        </>
                      ) : (
                        <>
                          <span>M</span>
                          <span>N</span>
                          <span className="letter-spacing-0">D</span>
                        </>
                      )}
                    </p>
                  </Link>
                </div>

                <button
                  className="navbar-toggler collapsed navicon justify-content-end"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>

                <div className="dlab-quik-search ">
                  <form action="#">
                    <input
                      name="search"
                      type="text"
                      className="form-control"
                      placeholder="Type to search"
                    />
                    <span id="quik-search-remove">
                      <i className="ti-close"></i>
                    </span>
                  </form>
                </div>

                <div className="extra-nav">
                  <div className="extra-cell">
                    <button
                      className="site-button-link"
                      onClick={() =>
                        handleLanguageChange(lang === "mn" ? "en" : "mn")
                      }
                    >
                      {lang === "mn" ? (
                        <img
                          src={require("../../images/new/eng-flag.png")}
                          alt="British flag"
                          style={{ height: 25, width: 40 }}
                        />
                      ) : (
                        <img
                          src={require("../../images/new/mn-flag.png")}
                          alt="Mongolian flag"
                          style={{ height: 25, width: 40 }}
                        />
                      )}
                    </button>
                  </div>
                </div>

                <div
                  className="header-nav navbar-collapse collapse justify-content-end"
                  id="navbarNavDropdown"
                >
                  <ul className="nav navbar-nav">
                    <li>
                      <Link style={{ textTransform: "none" }} to="#">
                        {lang === "mn" ? "Бидний Тухай" : "About Us"}
                        <i className="fa fa-chevron-down"></i>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link
                            style={{ textTransform: "none" }}
                            to="/greeting"
                          >
                            {lang === "mn"
                              ? "Захиралын мэндчилгээ"
                              : "Greetings from the Director"}
                          </Link>
                        </li>
                        <li>
                          <Link
                            style={{ textTransform: "none" }}
                            to="/about_us"
                          >
                            {lang === "mn"
                              ? "Компаний танилцуулга"
                              : "Company Profile"}
                          </Link>
                        </li>
                        <li>
                          <Link style={{ textTransform: "none" }} to="/history">
                            {lang === "mn" ? "Бидний түүх" : "Our History"}
                          </Link>
                        </li>
                        {/* <li>
                          <Link style={{ textTransform: "none" }} to="/tender">
                            {lang === "mn"
                              ? "Тендер мэдээлэл"
                              : "Tender Notices"}
                          </Link>
                        </li> */}
                      </ul>
                    </li>
                    <li>
                      <Link style={{ textTransform: "none" }} to="#">
                        {lang === "mn" ? "Хувьцаа Эзэмшигчдэд" : "Shareholders"}
                        <i className="fa fa-chevron-down"></i>
                      </Link>
                      <ul className="sub-menu right">
                        <li>
                          <Link
                            style={{ textTransform: "none" }}
                            to="/greeting-ceo"
                          >
                            {lang === "mn" ? "ТУЗ-ийн дарга" : "CEO's Message"}
                          </Link>
                        </li>
                        <li>
                          <Link
                            style={{ textTransform: "none" }}
                            to="/stock_price"
                          >
                            {lang === "mn" ? "Хувьцааны ханш" : "Stock Price"}
                          </Link>
                        </li>
                        <li className="submenu-item">
                          <Link
                            style={{ textTransform: "none", paddingRight: 15 }}
                          >
                            {lang === "mn"
                              ? "Компаний Засаглал"
                              : "Corporate Governance"}
                            <i
                              className="fa fa-chevron-right"
                              style={{ marginTop: 4 }}
                            ></i>
                          </Link>
                          <ul className="submenu">
                            <li>
                              <a href="/company_organizational">
                                {lang === "mn"
                                  ? "Засаглалын Бүтэц"
                                  : "Company Organizational"}
                              </a>
                            </li>
                            <li>
                              <a href="/shareholders">
                                {lang === "mn"
                                  ? "Хувьцаа Эзэмшигчдийн Бүтэц"
                                  : "Shareholders Organizational"}
                              </a>
                            </li>
                            <li>
                              <a href="/directors">
                                {lang === "mn"
                                  ? "Төлөөлөн Удирдах Зөвлөл"
                                  : "The Board of Directors"}
                              </a>
                            </li>
                            <li>
                              <a
                                href="/kebat"
                                style={{ textTransform: "none" }}
                              >
                                {lang === "mn"
                                  ? "КЭБАТ-ын талаарх мэдээлэл"
                                  : "КЭБАТ-ын талаарх мэдээлэл"}
                              </a>
                            </li>
                            {/* <li>
                              <a href="/">
                                {lang === "mn"
                                  ? "Дүрэм, Журам"
                                  : "Rules and Regulations"}
                              </a>
                            </li> */}
                          </ul>
                        </li>
                        {/* <li>
                          <Link
                            style={{ textTransform: "none" }}
                            to="/contact-3"
                          >
                            {lang === "mn"
                              ? "Ногдол ашиг"
                              : "Corporate Governance"}
                          </Link>
                        </li> */}
                        <li className="submenu-item">
                          <Link
                            style={{ textTransform: "none", paddingRight: 15 }}
                          >
                            {lang === "mn" ? "Тайлан үзүүлэлт" : "Reporting"}
                            <i
                              className="fa fa-chevron-right"
                              style={{ marginTop: 4 }}
                            ></i>
                          </Link>
                          <ul className="submenu">
                            <li>
                              <a href="/financial_report">
                                {lang === "mn"
                                  ? "Санхүүгийн тайлан"
                                  : "Financial Report"}
                              </a>
                            </li>
                            <li>
                              <a href="/activity_report">
                                {lang === "mn"
                                  ? "Үйл ажиллагааны тайлан"
                                  : "Activity Report"}
                              </a>
                            </li>
                            {/* <li>
                              <a href="/directors">
                                {lang === "mn"
                                  ? "Төлөөлөн Удирдах Зөвлөл"
                                  : "The Board of Directors"}
                              </a>
                            </li> */}
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <ul className="nav navbar-nav">
                    <li>
                      <a href="/#news">
                        {lang === "mn" ? "Мэдээ, Мэдээлэл" : "News"}
                      </a>
                    </li>
                  </ul>
                  <ul className="nav navbar-nav">
                    <li>
                      <a href="/#contact_us">
                        {lang === "mn" ? "Холбоо Барих" : "Contact Us"}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
      </Sticky>
    </>
  );
};
export default Header3;
