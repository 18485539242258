import React from "react";
import "./tender.scss"; // import your css file

const TenderDetail = () => {
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr overlay-black-middle bg-pt"
        style={{
          backgroundImage:
            "url(" + require("../../images/banner/bnr4.jpg") + ")",
          height: 70,
        }}
      ></div>
      <div className="content-block">
        <div className="section-full content-inner bg-white">
          <div className="container">
            <div className="row">
              <div className="blog-detail">
                <h1 className="m-b15 blog-detail-title">ТЕНДЕРИЙН УРИЛГА</h1>
                <p className="blog-detail-date">March 13, 2023</p>
                <ul className="blog-detail-text-list">
                  <li className="blog-detail-text-paragraph">
                    <p>1. Тендер шалгаруулалтын нэр</p>
                    Говь ХК ийн үйлдвэрт агааржуулалтын систем 300Кв.Т
                    суурилагдсан чадал бүхий узель инвертэржүүлж автоматжуулахад
                    шаардлагатай бараа материал нийлүүлэх, гүйцэтгэгч сонгон
                    шалгаруулалт
                  </li>
                  <li className="blog-detail-text-paragraph">
                    <p>2. Тендер шалгаруулалтын дугаар</p>
                    Говь ХК ны тендерт оролцогчдийг дараах 2 төрлийн ажил
                    гүйцэтгэх нээлттэй сонгон шалгаруулалтанд урьж байна.
                  </li>
                  <li className="blog-detail-text-paragraph">
                    <p>1. Тендер шалгаруулалтын нэр</p>
                    Говь ХК ийн үйлдвэрт агааржуулалтын систем 300Кв.Т
                    суурилагдсан чадал бүхий узель инвертэржүүлж автоматжуулахад
                    шаардлагатай бараа материал нийлүүлэх, гүйцэтгэгч сонгон
                    шалгаруулалт
                  </li>
                  <li className="blog-detail-text-paragraph">
                    <p>2. Тендер шалгаруулалтын дугаар</p>
                    Говь ХК ны тендерт оролцогчдийг дараах 2 төрлийн ажил
                    гүйцэтгэх нээлттэй сонгон шалгаруулалтанд урьж байна.
                  </li>
                  {/* <li className="blog-detail-text-paragraph">
                    <p>
                      ТЕНДЕРИЙН ТАЛААР НЭМЭЛТ ДЭЛГЭРЭНГҮЙ МЭДЭЭЛЛИЙГ 88107791
                      ДУГААРТ ХОЛБОГДОЖ АВНА УУ{" "}
                    </p>
                    <p>
                      ТЕНДЕРТ ОРОЛЦОГЧИЙН ҮНИЙН САНАЛ БОЛОН ХОЛБОГДОХ
                      МАТЕРИАЛУУДИЙГ 2023.03.10-2023.03.15 НЫ 18:00 ЦАГ ХҮРТЭЛ
                      ХАН-УУЛ ДҮҮРЭГ, 3-Р ХОРОО ҮЙЛДВЭРИЙН ГУДАМЖ,ГОВЬ ХК-ИЙН
                      ОФФИСИЙН 1 ДАВХАРТ, РЕСЕПШИНД ХҮЛЭЭЛГЭН ӨГНӨ ҮҮ
                    </p>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenderDetail;
