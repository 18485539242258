import React from "react";
import { Link } from "react-router-dom";
import { useLangContext } from "../../lang-context";

const Footer = () => {
  const { lang } = useLangContext();

  return (
    <>
      <footer className="site-footer style1">
        <div className="footer-top2">
          <div className="container2">
            <div className="row2">
              {/* <div className="col-md-12 col-sm-6">
                <div className="widget widget_about">
                  <h4 className="footer-title">
                    {lang === "mn" ? "Манай компани" : "About Industry"}
                  </h4>
                  <p>
                    1999 онд “Сэлэнгэ дулаанхан” ХК нэртэйгээр байгуулагдаж 2017
                    онд “ЭМ ЭН ДИ” ХК болгон нэрээ өөрчлөн ашигт малтмалын
                    хайгуул, ашиглалтын чиглэлээр үйл ажиллагаа явуулж эхлэж
                    байгаа болно.
                  </p>
                  <Link to={"/about_us"} className="readmore">
                    {lang === "mn" ? "Дэлгэрэнгүй" : "Read More"}
                  </Link>
                </div>
              </div> */}
              {/* <div className="col-md-4 col-sm-6">
                <div className="widget">
                  <h4 className="footer-title">Usefull Link</h4>
                  <ul className="list-2">
                    <li>
                      <Link to={"/about-1"}>About Us</Link>
                    </li>
                    <li>
                      <Link to={"/blog-grid-2"}>Blog</Link>
                    </li>
                    <li>
                      <Link to={"/services-1"}>Services</Link>
                    </li>
                    <li>
                      <Link to={"/privacy-policy"}>Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to={"/portfolio-grid-2"}>Projects </Link>
                    </li>
                    <li>
                      <Link to={"/help-desk"}>Help Desk </Link>
                    </li>
                    <li>
                      <Link to={"/shop"}>Shop</Link>
                    </li>
                    <li>
                      <Link to={"/contact-1"}>Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="widget widget_subscribe">
                  <h4 className="footer-title">Usefull Link</h4>
                  <p>
                    If you have any questions. Subscribe to our newsletter to
                    get our latest products.
                  </p>
                  <form className="dzSubscribe" ref={form} onSubmit={sendEmail}>
                    <div className="dzSubscribeMsg"></div>
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          name="dzEmail"
                          required="required"
                          type="email"
                          className="form-control"
                          placeholder="Your Email Address"
                        />
                        <div className="input-group-addon">
                          <button
                            name="submit"
                            value="Submit"
                            type="submit"
                            className="site-button fa fa-paper-plane-o"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer-bottom footer-line">
          <div className="container">
            <div className="footer-bottom-in">
              <div className="footer-bottom-logo">
                <Link
                  to={"/"}
                  style={{ background: "none", textAlign: "center", width: 150 }}
                >
                  <img src={require("../../images/mnd/logo.png")} alt="" />
                  <p className="logo-text">
                    {lang === "mn" ? (
                      <>
                        <span>ЭМ</span>
                        <span>ЭН</span>
                        <span className="letter-spacing-0">ДИ</span>
                      </>
                    ) : (
                      <>
                        <span>M</span>
                        <span>N</span>
                        <span className="letter-spacing-0">D</span>
                      </>
                    )}
                  </p>
                </Link>
              </div>
              <div className="footer-bottom-social">
                <ul className="dlab-social-icon dez-border">
                  <li>
                    <Link className="fa fa-facebook" to={"/#"}></Link>
                  </li>
                  <li>
                    <Link className="fa fa-twitter" to={"/#"}></Link>
                  </li>
                  <li>
                    <Link className="fa fa-linkedin" to={"/#"}></Link>
                  </li>
                  <li>
                    <Link className="fa fa-pinterest" to={"/#"}></Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
