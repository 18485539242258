import React from "react";
import { useLangContext } from "../../lang-context";

function Kebat() {
  const { lang } = useLangContext();
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr overlay-black-middle bg-pt"
        style={{
          backgroundImage:
            "url(" + require("../../images/banner/bnr4.jpg") + ")",
          height: 70,
        }}
      ></div>
      <div className="content-block">
        <div className="section-full content-inner bg-white">
          <div className="container">
            <div
              className="content-bx1 m-b40"
              style={{
                paddingLeft: 0,
              }}
            >
              <h3
                className="m-b15 title"
                style={{ textTransform: "uppercase" }}
              >
                {lang === "mn"
                  ? "КЭБАТ-ЫН ТАЛААРХИ МЭДЭЭЛЭЛ"
                  : "КЭБАТ-ЫН ТАЛААРХИ МЭДЭЭЛЭЛ"}
              </h3>
            </div>
            <div class="table-responsive">
              <table
                class="table dividend trade_table table-bordered table-striped table-hover table-condensed"
                role="grid"
                aria-describedby="dt_basic_info"
              >
                <thead>
                  <tr>
                    <th style={{ width: 30 }}>№</th>
                    <th width="250px">Овог Нэр</th>
                    <th>Албан тушаал</th>
                    <th>Эзэмшиж буй хувьцааны хувь</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Батсүх Эрдэнэтуяа</td>
                    <td>ТУЗ-ийн Нарийн бичгийн дарга</td>
                    <td>0 %</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Н. Ганчимэг</td>
                    <td>ТУЗ-ийн дарга</td>
                    <td>0 %</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Намсрай Отгон-Од</td>
                    <td>Гүйцэтгэх захирал</td>
                    <td>33.89 %</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Kebat;
