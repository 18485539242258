// import React from 'react';
import React, { Component } from "react";
import "./App.css";
import { LangProvider } from "./lang-context";
import Router from "./markup/router";
import "./plugins/flaticon/beer/flaticon.css";
import "./plugins/flaticon/flaticon.css";
import "./plugins/fontawesome/css/font-awesome.min.css";
import "./plugins/line-awesome/css/line-awesome.min.css";

class App extends Component {
  render() {
    return (
      <LangProvider>
        <Router />
      </LangProvider>
    );
  }
}

export default App;
