import React from "react";
import { useLangContext } from "../../lang-context";

const CompanyDetail = ({}) => {
  const { lang } = useLangContext();
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr overlay-black-middle bg-pt"
        style={{
          backgroundImage:
            "url(" + require("../../images/banner/bnr4.jpg") + ")",
          height: 70,
        }}
      ></div>

      <div className="content-block">
        <div className="section-full content-inner bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 m-b30">
                <h1 className="m-b15">
                  {lang === "mn"
                    ? "КОМПАНИЙН ҮЙЛ АЖИЛЛАГАА"
                    : "PROJECT INFORMATION"}
                </h1>
                <p>
                  “ЭМ ЭН ДИ” ХК нь 1999 оноос хойш үйл ажиллагаагаа явуулаагүй
                  байгаа бөгөөд 2017 онд “Сэлэнгэ дулаанхан”ХК-ийн нэрээ өөрчлөн
                  “ЭМ ЭН ДИ” ХК нь болгов. Өнөөгийн нөхцөл байдалд компани нь
                  Монгол Улсын болон олон улсын эдийн засгийн нөхцөл байдалд
                  дахин үнэлгээ хийн, Монгол улсад хэрэгжүүлэх боломжтой шинэ
                  төслүүдийг эрэлхийлж боловсруулж байна.
                </p>
                <p>
                  НББ-ийн дансны нэгдсэн зааврын дагуу үндсэн үйл ажиллагаагаа
                  тохируулан баланс болон орлоо, зарлага санхүүгийн жилийн
                  татвар ноогдох тайлангаа Монгол улсын аж ахуйн нэгж,
                  байгууллагын орлогын албан татварын тухай хууль бусад холбогох
                  хуулийн хүрээнд бэлтгэн тайлагнадаг.
                </p>
                {/* <div className="row widget widget_getintuch widget_getintuch-pro-details m-lr0">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                    <div className="pro-details">
                      <i className="ti ti-user"></i>
                      <strong>CLIENT</strong> Martin Stewart
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                    <div className="pro-details">
                      <i className="ti ti-location-pin"></i>
                      <strong>LOCATION</strong>London, UK
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                    <div className="pro-details">
                      <i className="ti ti-ruler-alt-2"></i>
                      <strong>SIZE</strong>1,200m<sup>2</sup>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-lr0">
                    <div className="pro-details">
                      <i className="ti ti-home"></i>
                      <strong>TYPE</strong>Residential Project
                    </div>
                  </div>
                </div> */}
                <h1 className="m-b15">
                  {lang === "mn"
                    ? "Cанхүүгийн мэдээлэл"
                    : "Financial information"}{" "}
                </h1>
                <p>
                  Хараат бус аудитын талаархи дүгнэлтийг “Акпар аудит” ХХК-иар
                  баталгаажуулсан болно. Санхүүгийн тайлангаа НББ-ийн аккурэл
                  суурьд үндэслэн бэлтгэдэг ба холбогдох хуулийн дагуу улирлын
                  тайлангаа дараа сарын 20-ны дотор, жилийн тайлангаа дараа оны
                  2 дугаар сарын 10-ны дотор баталгаажуулан Сангийн яам болон
                  Татварын хэлтэс, МХБ, СЗХ зэрэг байгууллагуудад хүргүүлэн
                  ажиллаж байна.
                </p>
                {/* <div className="row">
                  <div className="col-lg-12">
                    <div className="icon-bx-wraper m-b30 left">
                      <div className="icon-md">
                        <Link to="#" className="icon-cell text-primary">
                          <i className="flaticon-factory"></i>
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h5 className="dlab-tilte">Material Engineering</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit, sed diam nonummy nibh euismod..
                        </p>
                      </div>
                    </div>
                    <div className="icon-bx-wraper m-b30 left">
                      <div className="icon-md">
                        <Link to="#" className="icon-cell text-primary">
                          <i className="flaticon-settings"></i>
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h5 className="dlab-tilte">Chemical Research</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit, sed diam nonummy nibh euismod..
                        </p>
                      </div>
                    </div>
                    <div className="icon-bx-wraper left">
                      <div className="icon-md">
                        <Link to="#" className="icon-cell text-primary">
                          <i className="flaticon-worker"></i>
                        </Link>
                      </div>
                      <div className="icon-content">
                        <h5 className="dlab-tilte">Petroleum Engineering</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit, sed diam nonummy nibh euismod..
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 m-b30">
                    <img
                      alt=""
                      src={require("../../images/new/original/company-1.jpeg")}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 m-b30">
                    <img
                      alt=""
                      src={require("../../images/new/original/company-11.jpeg")}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 m-b30">
                    <img
                      alt=""
                      src={require("../../images/new/original/company-7.jpeg")}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 m-b30">
                    <img
                      alt=""
                      src={require("../../images/new/original/company-8.jpeg")}
                    />
                  </div>
                  <div className="col-lg-12 m-b30">
                    <img
                      alt=""
                      src={require("../../images/new/original/company-3.jpeg")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompanyDetail;
