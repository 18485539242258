import React from "react";
import { useLangContext } from "../../lang-context";

function Directors() {
  const { lang } = useLangContext();
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr overlay-black-middle bg-pt"
        style={{
          backgroundImage:
            "url(" + require("../../images/banner/bnr4.jpg") + ")",
          height: 70,
        }}
      ></div>
      <div className="content-block">
        <div className="section-full content-inner bg-white">
          <div className="container">
            <div
              className="content-bx1 m-b40"
              style={{
                paddingLeft: 0,
              }}
            >
              <h3
                className="m-b15 title"
                style={{ textTransform: "uppercase" }}
              >
                {lang === "mn"
                  ? "ТӨЛӨӨЛӨН УДИРДАХ ЗӨВЛӨЛИЙН ГИШҮҮДИЙН МЭДЭЭЛЭЛ"
                  : "The Board of Directors"}
              </h3>
            </div>
            <div class="table-responsive">
              <table
                class="table dividend trade_table table-bordered table-striped table-hover table-condensed"
                role="grid"
                aria-describedby="dt_basic_info"
              >
                <thead>
                  <tr>
                    <th style={{ width: 30 }}>№</th>
                    <th>Овог Нэр</th>
                    <th>Гишүүнчлэл</th>
                    <th>Эзэмшиж буй хувьцааны хувь</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Пионг Чунг Фах</td>
                    <td>ТУЗ-ийн гишүүн </td>
                    <td>17.61 %</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Чунг Чии Иенн </td>
                    <td>ТУЗ-ийн гишүүн </td>
                    <td>17.61 %</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Лю Вен Тейк </td>
                    <td>ТУЗ-ийн гишүүн </td>
                    <td>5.14 %</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Намсрай Отгон-Од</td>
                    <td>ТУЗ-ийн гишүүн </td>
                    <td>33.89 %</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Намсрай Ганчимэг</td>
                    <td>ТУЗ-ийн дарга </td>
                    <td>0 %</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Цэрэндорж Болд</td>
                    <td>Хараат бус ТУЗ-ийн гишүүн </td>
                    <td>0 %</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Баяр-Эрдэнэ Сарнай</td>
                    <td>Хараат бус ТУЗ-ийн гишүүн </td>
                    <td>0 %</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>Намсрай Ганбаатар</td>
                    <td>Хараат бус ТУЗ-ийн гишүүн </td>
                    <td>0 %</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>Намсрай Гансувд</td>
                    <td>ТУЗ-ийн гишүүн </td>
                    <td>0 %</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Directors;
