import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Footer from "./markup/layout/footer";
import Header from "./markup/layout/header";

const Layout = ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (!window.location.hash) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <>
      <Header />
      <div>{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
