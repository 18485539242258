import React from "react";
import { useLangContext } from "../../lang-context";

const Organizational = () => {
  const { lang } = useLangContext();
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr overlay-black-middle bg-pt"
        style={{
          backgroundImage:
            "url(" + require("../../images/banner/bnr4.jpg") + ")",
          height: 70,
        }}
      ></div>

      <div className="content-block">
        <div className="section-full content-inner bg-white">
          <div className="container">
            <div className="row">
              <h1 className="m-b40 text-center">
                {lang === "mn" ? "ЭМ ЭН ДИ Бүтэц, Зохион Байгуулалтын Схем" : "COMPANY ORGANIZATIONAL"}
              </h1>
            </div>
            <div className="row">
              <img
                style={{ maxWidth: 800, margin: "auto", paddingLeft: 30 }}
                src={require("../../images/mnd/company-structure-4.png")}
                alt="1940s"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organizational;
