import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export const LangContext = createContext(undefined);

export const LangProvider = (props) => {
  const [lang, setLang] = useState(localStorage.getItem("language") || "mn");

  const handleLanguageChange = useCallback((newLanguage) => {
    setLang(newLanguage);
    localStorage.setItem("language", newLanguage);
  }, []);

  const value = useMemo(() => {
    return {
      lang,
      handleLanguageChange,
    };
  }, [lang, handleLanguageChange]);

  return (
    <LangContext.Provider value={value}>{props.children}</LangContext.Provider>
  );
};

export const useLangContext = () => {
  const context = useContext(LangContext);
  return context;
};
