import React from "react";
import { useLangContext } from "../../lang-context";
import "./companyHistory.scss";

const CompanyHistory = () => {
  const { lang } = useLangContext();
  return (
    <div className="page-content bg-white">
      <div
        className="dlab-bnr-inr overlay-black-middle bg-pt"
        style={{
          backgroundImage:
            "url(" + require("../../images/banner/bnr4.jpg") + ")",
          height: 70,
        }}
      ></div>

      <div className="content-block">
        <div className="section-full content-inner bg-white">
          <div className="container">
            <div className="row">
              <h1 className="m-b40 text-center">
                {lang === "mn" ? "БИДНИЙ ТҮҮХ" : "OUR HISTORY"}
              </h1>
              <div className="history-container">
                <div className="history-card">
                  <div className="history-image history-image-right">
                    <img
                      src={require("../../images/new/original/company-1.jpeg")}
                      alt="1920s"
                    />
                  </div>
                  <div className="history-content">
                    <h2 className="history-year">1999</h2>
                    <p className="history-text">
                      {lang === "mn"
                        ? "Манай компань нь “Сэлэнгэ дулаанхан” ХК нэртэйгээр байгуулагдсан."
                        : "Our company was established under the name of “Selenge Dulaakhan“ JSC."}
                    </p>
                  </div>
                </div>
                <div className="history-card">
                  <div className="history-content">
                    <h2 className="history-year">2017</h2>
                    <p className="history-text">
                      {lang === "mn"
                        ? "“ЭМ ЭН ДИ” ХК болгон нэрээ өөрчлөн ашигт малтмалын хайгуул, ашиглалтын чиглэлээр үйл ажиллагаа явуулж эхлэж байгаа болно. Говь-алтай аймгийн Тайшир сумын нутагт Хавчигийн аманд байрлах Алтангадас алтны шороон ордны уурхайг ашиглах төсөл нь Монголын баруун бүс нутаг, тэр дундаа олон үндэстний өлгий нутаг Говь-алтай аймгийн хэмжээнд уул уурхайн томоохон төсөл болон хөгжиж, Монгол улсын хөгжил дэвшилд бодитой хувь нэмэр оруулахаар тооцоо судалгаа гаргаад байна. Манай хариуцах төсөл нь хариуцлагатай уул уурхайн тэргүүн туршлага, аюулгүй ажиллагааны стандартуудыг үйл ажиллагаандаа мөрдлөг болгож, төрөөс эрдэс баялгийн салбарт баримтлах бодлогын хүрээнд салбартаа тэргүүлэх компани болохыг эрхэм зорилгоо болгож ажиллаж байна."
                        : "After changing its name to “MND“ JSC, it is starting to operate in the field of mineral exploration and exploitation. The project to use the Altangadas gold placer mine located at the mouth of Khavchi in Taishir Sum area of Gobi-Altay Province is expected to develop into a major mining project in the western region of Mongolia, including Gobi-Altay Province, home to many ethnic groups, and to make a real contribution to the development of Mongolia. research is being done. The project we are responsible for follows the best practice of responsible mining and safety standards in our operations, and our mission is to become a leading company in the field within the framework of the governments policy in the mineral resources sector."}
                    </p>
                  </div>
                  <div className="history-image history-image-left">
                    <img
                      src={require("../../images/new/original/company-11.jpeg")}
                      alt="1930s"
                    />
                  </div>
                </div>
                <div className="history-card">
                  <div className="history-image history-image-right">
                    <img
                      src={require("../../images/new/original/company-9.jpeg")}
                      alt="1940s"
                    />
                  </div>
                  <div className="history-content">
                    <h2 className="history-year">2021</h2>
                    <p className="history-text">
                      {lang === "mn"
                        ? "Бид 2021 оны 02 дугаар сарын 1-ий өдөр Алтан гадас майнинг групп , Рийлзоин рэсурс ХХК-тай хамтын ажиллагааны гэрээнд гарын үсэг зурсанаар үйл ажиллагаа тогтворжиж эхлэсэн."
                        : "We are Polar Mining on February 1, 2021 group, in a cooperation agreement with Realzoin Resources LLC With the signing, the operation began to stabilize."}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyHistory;
