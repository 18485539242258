import React from "react";
import { Link } from "react-router-dom";
import { useLangContext } from "../../lang-context";
const bg = require("../../images/banner/bnr1.jpg");
const Error404 = ({}) => {
  const { lang } = useLangContext();
  return (
    <>
      <div
        className="dlab-bnr-inr full-height overlay-black-dark"
        style={{ backgroundImage: "url(" + bg + ")" }}
      >
        <div className="container">
          <div className="row max-w700 dlab-bnr-inr-entry dz_error-404-area align-m dz_error-404 text-white m-auto">
            <div className="col-lg-4 col-md-4 m-tb30">
              <div className="bg-primary dz_error">404</div>
            </div>
            <div className="col-lg-8 col-md-8 m-b30 text-left">
              <h2 className="error-head">
                {lang === "mn" ? "Алдаа гарлаа!" : "Something went wrong!"}
              </h2>
              <p className="font-16">
                {lang === "mn"
                  ? "Уучлаарай, таны хайж буй хуудас олдсонгүй."
                  : "We are sorry but it appears that the page you are looking for could not be found."}
              </p>
              <p className="font-16">
                {lang === "mn"
                  ? "Та товчлуур дээр дарж үндсэн хуудас руу буцаж болно."
                  : "You can go back to the Main Page by clicking the button."}
              </p>
              <Link to="/" className="site-button">
                {lang === "mn" ? "Үндсэн хуудас руу очих" : "Go To Home"}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Error404;
