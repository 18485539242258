import React from "react";
import { Link } from "react-router-dom";
import { useLangContext } from "../../../lang-context";

const newsImage1 = require("../../../images/new/news1.png");
const News1 = () => {
  const { lang } = useLangContext();

  return (
    <>
      {" "}
      <div className="dlab-post-meta">
        <ul>
          <li className="post-date">
            {" "}
            <strong>09 Nov</strong> <span> 2017</span>{" "}
          </li>
          <li className="post-author">
            {" "}
            By <Link to="/#">Uuganbold</Link>{" "}
          </li>
        </ul>
      </div>
      <div className="dlab-post-title ">
        <h4 className="post-title m-t0">
          <Link to="/blog-single-sidebar">
            “СЭЛЭНГЭ ДУЛААНХАН” ХК-ийн оноосон нэрийг “ЭМ ЭН ДИ” ХК болгон
            өөрчиллөө.
          </Link>
        </h4>
      </div>
      <div className="dlab-post-text">
        <img className="alignleft" width="300" src={newsImage1} alt="" />
        <p>
          Монголын хөрөнгийн биржийн Гүйцэтгэх захирлын 2017 оны 07 дугаар сарын
          05-ны өдрийн А/116 тушаал болон Санхүүгийн зохицуулах хорооны даргын
          2017 оны 11 дүгээр сарын 06-ны өдрийн 472 дугаар тушаалаар Монголын
          хөрөнгийн биржид бүртгэлтэй “Сэлэнгэ Дулаанхан” ХК-ийн оноосон нэрийг
          “Эм Эн Ди” ХК болгон өөрчиллөө.
        </p>
      </div>
    </>
  );
};

export default News1;
